<template>
  <div class="deepsel-edit-bar">
    <div class="deepsel-edit-bar-left">
      <a class="deepsel-edit-bar-link deepsel-editor" href="/web">
        <i class="fas fa-tachometer-alt"></i>
        Dashboard
      </a>
      <a class="deepsel-edit-bar-link deepsel-editor" :href="$store.state.page_path === 'blog' && $store.state.blog_detail ?
        `/web#model=blog.post&view_type=form&id=${$store.state.blog_detail.id}` : `/build/wrap/${$store.state.page_path || 'home'}`
      ">
        <i class="fas fa-pen-alt"></i>
        Edit
      </a>
    </div>
    <div class="deepsel-edit-bar-center dropdown">
      <vs-tooltip shadow interactivity bottom>
        <a class="deepsel-edit-bar-link deepsel-edit-bar-user deepsel-editor" href="#">
          <i class="fas fa-caret-down mr-2"></i>
          {{ $store.state.page_path || 'home' }}
        </a>
        <template #tooltip>
          <div class="list-pages">
            <a v-for="i in $store.state.common_data.pages" :key="i.id" :class="i.path === $store.state.page_path ? 'active' : ''" class="deepsel-editor dropdown-single-item text-left" :href="'/' + i.path">
              <i class="fas fa-pager mr-2"></i>
              {{i.name}}
            </a>
          </div>
        </template>
      </vs-tooltip>

    </div>
    <div class="deepsel-edit-bar-right dropdown">
      <vs-tooltip shadow interactivity bottom>
        <a class="deepsel-edit-bar-link deepsel-edit-bar-user deepsel-editor" href="#">
          <i class="fas fa-caret-down"></i>
          <img
              :src="$store.state.common_data.user.image"
              class="deepsel-edit-bar-avatar"
              :alt="$store.state.common_data.user.name"
          />
          Good {{ greeting }}, {{ $store.state.common_data.user.name }}
        </a>
        <template #tooltip>
          <div style="min-width: 10rem;">
            <a class="dropdown-single-item deepsel-editor text-left" href="/my/account">
              <i class="fa fa-user-circle mr-2 color-black"></i>
              Profile
            </a>
            <a class="dropdown-single-item deepsel-editor text-left" href="/web/session/logout?redirect=/web">
              <i class="far fa-times-circle mr-2"></i>
              Logout
            </a>
          </div>
        </template>
      </vs-tooltip>
    </div>
    <component :is="`style`">
      #app { margin-top: 26px; }
    </component>
  </div>
</template>

<script>
export default {
  name: "EditBar",
  computed: {
    greeting() {
      let hour = new Date().getHours()
      if (hour < 12) return 'morning'
      if (hour < 17) return 'afternoon'
      return 'evening'
    }
  }
}
</script>


<style scoped>
.list-pages {
  min-width: 10rem;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.list-pages::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #333;
  border-radius: 10px;
}
.list-pages::-webkit-scrollbar {
  width: 3px;
  background-color: white;

}
.deepsel-edit-bar {
  top: 0;
  position: fixed;
  background-image: linear-gradient(to right, #8A2387, #E94057, #F27121);
  height: 26px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Montserrat, Arial, sans-serif;
  color: white !important;
  padding: 0.3rem;
  font-size: 11px;
  padding-right: 1rem;
  z-index: 9999;
}

.deepsel-edit-bar-link {
  text-decoration: none;
  color: white !important;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.deepsel-edit-bar-user {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /*margin-right: 0.7rem;*/
}

.deepsel-edit-bar-avatar {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.deepsel-edit-bar-right {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 15px;
  left: 10px;
  background: white;
  box-shadow: 0 5px 19px rgba(6, 21, 40, 0.3);
  border-radius: 1rem;
  width: 100%;
  display: none;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
}

.dropdown:hover .dropdown-content {
  display: flex;
}

.dropdown-single-item {
  display: block;
  width: 100%;
  border-radius: 1rem;
    min-width: 3rem;

  padding: .5rem 1rem;
  border: 0;
  cursor: pointer;
  color: black !important;
}

.dropdown-single-item:focus, .dropdown-single-item:hover {
  color: #006BB4 !important;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-single-item.active {
  color: #006BB4 !important;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-single-item.active i {
  color: #006BB4 !important;
}

.deepsel-edit-bar i {
  color: white !important;
}

.dropdown-single-item i {
  color: black !important;
}

.dropdown-single-item:focus i, .dropdown-single-item:hover i {
  color: #006BB4 !important;
}
</style>